import { db } from "../firebase";
import { updateProfile } from "firebase/auth";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import {
  cleanAndValidatePhoneNumber,
  checkUniqueEmail,
  checkUniquePhoneNumber,
} from "./utils";

export const otherUsers = {
  actions: {
    async updateOtherUser(
      _,
      { collectionName, user, oldEmail, oldPhoneNumber }
    ) {
      const updateUser = {};
      // TODO: get groups working again
      try {
        if (user.email !== oldEmail) {
          await checkUniqueEmail(user.email);
        }

        if (collectionName === "users") {
          if (user.role === "super_admin") {
            updateUser.usersRole = {
              super_admin: true,
              unit_member: user.usersRole.unit_member,
            };
          } else if (user.role === "unit_admin") {
            updateUser.usersRole = {
              unit_admin: user.usersRole.unit_member,
              unit_member: user.usersRole.unit_member,
            };
          } else if (user.role === "unit_member") {
            updateUser.usersRole = { unit_member: user.usersRole.unit_member };
          }
        }

        if (user.groups) {
          updateUser.groups = user.groups;
        }

        if (user.phoneNumber.length > 0) {
          user.phoneNumber = cleanAndValidatePhoneNumber(
            user.phoneNumber.replace("+1", "")
          );

          if (user.phoneNumber !== oldPhoneNumber) {
            await checkUniquePhoneNumber(user.phoneNumber);
          }
        }
        updateUser.phoneNumber = user.phoneNumber;

        updateUser.displayName = user.displayName;
        await updateProfile(user, {
          displayName: updateUser.name,
        });

        await updateDoc(doc(db, collectionName, user.id), updateUser);
      } catch (error) {
        console.error("Error updating user:", error.message);
        throw error;
      }
    },
    async addOtherUser(_, { collectionName, user }) {
      try {
        if (collectionName === "users") {
          throw new Error("Only manual users can be added");
        }
        if (!user.displayName) {
          throw new Error("Display name is required");
        }

        await checkUniqueEmail(user.email);

        if (user.phoneNumber.length > 0) {
          user.phoneNumber = cleanAndValidatePhoneNumber(
            user.phoneNumber.replace("+1", "")
          );

          await checkUniquePhoneNumber(user.phoneNumber);
        }

        await addDoc(collection(db, collectionName), user);
      } catch (error) {
        console.error("Error adding manual user:", error.message);
        throw error;
      }
    },
    async deleteOtherUser(_, { collectionName, user }) {
      try {
        if (collectionName === "users") {
          throw new Error("Only manual users can be deleted");
        }
        await deleteDoc(doc(db, collectionName, user.id));
      } catch (error) {
        console.error("Error deleting user:", error.message);
      }
    },
  },
};
