<template>
  <generic-modal @close="closeModal">
    <template v-slot:header>
      <h2>
        Send {{ sendingUsers.length }} Messages to
        {{ sendingGroup.groupName }} Group
      </h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="saveChanges">
        <div class="form-group">
          <label for="name">Message:</label>
          <textarea type="text"></textarea>
        </div>
        <div style="color: brown">
          By clicking "Send" you agree to only send messages that relate to the
          Washington Stake's Mission Plan and will help bring others closer to
          Christ.
        </div>
        <button type="submit" class="form-button">Send Message</button>
      </form>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from "./GenericModal.vue";

export default {
  components: {
    GenericModal,
  },
  data() {
    return {};
  },
  props: {
    // Add props here
    sendingGroup: {
      type: Object,
      required: true,
    },
    sendingUsers: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close-send-message-modal");
    },
  },
  created() {},
};
</script>
