<template>
  <nav>
    <div class="navbar-container">
      <div class="left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="35"
          height="35"
          fill="#2196F3"
        >
          <circle
            cx="50"
            cy="50"
            r="48"
            fill="#fff"
            stroke="#2196F3"
            stroke-width="4"
          />

          <rect x="30" y="30" width="40" height="40" fill="#2196F3" />
          <line
            x1="30"
            y1="50"
            x2="70"
            y2="50"
            stroke="#fff"
            stroke-width="4"
          />
          <line
            x1="50"
            y1="30"
            x2="50"
            y2="70"
            stroke="#fff"
            stroke-width="4"
          />
        </svg>
      </div>

      <div class="center">
        <h1 class="page-title">Washington Utah Stake</h1>
      </div>

      <div class="right">
        <!-- TODO: Need to make this dropdown close when a link is clicked -->
        <div @click.stop v-if="user" ref="dropdown" class="dropdown">
          <button class="dropbtn" @click="dropdownOpen = !dropdownOpen">
            <HamburgerMenu />
          </button>
          <div
            ref="dropdownContent"
            class="dropdown-content"
            v-show="dropdownOpen"
          >
            <a @click="$router.push('/')">My Profile</a>
            <a
              v-if="user.usersRole && user.usersRole.unit_admin"
              @click="$router.push(`/groups/${user.usersRole.unit_admin}`)"
              >Unit Groups</a
            >
            <a
              v-if="
                user.usersRole &&
                (user.usersRole.unit_admin || user.usersRole.super_admin)
              "
              @click="$router.push(`/members`)"
              >Members</a
            >
            <a
              v-if="user.usersRole && user.usersRole.super_admin"
              @click="$router.push('/units')"
              >Units</a
            >
            <a @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../firebase";
import HamburgerMenu from "./icons/HamburgerMenu.vue";

export default {
  data() {
    return {
      dropdownOpen: false,
    };
  },
  components: {
    HamburgerMenu,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      auth.signOut();
      this.$router.push("/login");
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
nav {
  background-color: #3f51b5; /* Material Design Blue */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.left,
.center,
.right {
  flex: 1; /* Equal flex distribution */
  display: flex;
  align-items: center;
}

.logo {
  max-height: 40px; /* Adjust the max height as needed */
}

.page-title {
  margin: 0;
  color: #fff;
}

.center {
  align: center;
}
.center > * {
  margin: auto;
}

.right {
  display: flex;
  justify-content: flex-end; /* Pushes the element to the right */
  align-items: center; /* Align items vertically */
}

/* child of right */
.right > * {
  margin-left: auto; /* Aligns the button to the right */
}
.logout-button {
  background-color: #e44d26; /* Material Design Orange */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.logout-button:hover {
  background-color: #ff7043; /* Lighter orange on hover */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.dropbtn:hover {
  background-color: #388e3c;
}

.dropdown-content > a {
  cursor: pointer;
}

.dropdown-content > a:hover {
  background-color: #3f51b5;
  color: white;
}
</style>
