<template>
  <generic-modal @close="closeModal">
    <template v-slot:header>
      <h2>Edit Member</h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="saveChanges">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="editedUser.displayName" />
        </div>
        <hr />
        <div class="form-group">
          <label for="email">Email:</label>
          <input
            type="email"
            id="email"
            v-model="editedUser.email"
            :disabled="collectionName == 'users'"
          />
        </div>
        <hr />
        <div class="form-group">
          <label for="phone">Phone Number:</label>
          <input type="tel" id="phone" v-model="editedUser.phoneNumber" />
        </div>
        <hr />
        <label for="groups">Groups:</label>
        <div
          style="padding-left: 20px"
          v-for="group in ['prayer', 'connect', 'teaching', 'retaining']"
          :key="group"
          class="form-group"
        >
          <label class="inline" :for="group">{{ group }}</label>
          <label class="switch">
            <input
              type="checkbox"
              :id="group"
              :value="group"
              v-model="editedUser.groups"
            />
            <span class="slider"></span>
          </label>
        </div>
        <hr />
        <div class="form-group">
          <label for="unit">Unit:</label>
          <select id="unit" v-model="editedUser.usersRole.unit_member">
            <option v-for="unit in units" :key="unit.id" :value="unit.id">
              {{ unit.name }}
            </option>
          </select>
        </div>
        <hr />
        <div class="form-group">
          <label v-if="collectionName === 'users'" for="role">Role:</label>
          <select
            v-if="collectionName === 'users'"
            id="role"
            v-model="editedUser.role"
          >
            <option value="unit_member">Unit Member</option>
            <option value="unit_admin">Unit Admin</option>
            <option
              v-if="currentUser.usersRole.super_admin"
              value="super_admin"
            >
              Super Admin
            </option>
          </select>
        </div>
        <button class="form-button" type="submit">Update</button>
        <span style="color: red" class="error-message" v-if="errorMessage">
          {{ errorMessage }}
        </span>
      </form>
    </template>
  </generic-modal>
</template>

<script>
import { mapActions } from "vuex";
import GenericModal from "./GenericModal.vue";

export default {
  components: {
    GenericModal,
  },
  data() {
    return {
      editedUser: {
        role: this.userRole(),
        ...JSON.parse(JSON.stringify(this.user)),
      },
      errorMessage: null,
    };
  },
  props: {
    currentUser: { type: Object, required: true },
    user: { type: Object, required: true },
    collectionName: { type: String, required: true },
    units: { type: Object, required: true },
  },
  methods: {
    ...mapActions(["updateOtherUser"]),
    closeModal() {
      this.$emit("close");
    },
    async saveChanges() {
      try {
        await this.updateOtherUser({
          collectionName: this.collectionName,
          user: this.editedUser,
          oldEmail: this.user.email,
          oldPhoneNumber: this.user.phoneNumber,
        });
        this.closeModal();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    userRole() {
      if (this.user.usersRole.super_admin) {
        return "super_admin";
      } else if (this.user.usersRole.unit_admin) {
        return "unit_admin";
      } else if (this.user.usersRole.unit_member) {
        return "unit_member";
      }
      return null;
    },
  },
};
</script>
