<template>
  <div class="form-container">
    <h2>Forgot Password</h2>
    <form @submit.prevent="triggerSendPasswordResetEmail" class="form-form">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" required />
      </div>
      <div v-if="message" class="form-message">{{ message }}</div>
      <button type="submit" class="form-button">Send Email</button>
      <router-link to="/login" class="form-link">Login</router-link>
      <router-link to="/signup" class="form-link">Sign Up</router-link>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "../styles/form-styles.css";

export default {
  data() {
    return {
      email: "",
      password: "",
      message: "",
    };
  },
  methods: {
    ...mapActions(["sendPasswordResetEmail"]),
    async triggerSendPasswordResetEmail() {
      try {
        await this.sendPasswordResetEmail({ email: this.email });
      } catch (error) {
        console.error("Error sending password reset email:", error.message);
      }
      this.message = "Email sent!";
    },
  },
};
</script>
