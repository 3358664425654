<template>
  <!-- Only the table should scroll with the headers being sticky -->
  <input
    type="text"
    @input="triggerSearch()"
    v-model="search"
    placeholder="Search"
    class="search"
  />
  <table class="material-table">
    <thead>
      <tr>
        <th v-if="bulkActions.show">
          <input
            style="display: inline-block; width: inherit"
            type="checkbox"
            :checked="allBulkSelected"
            @change="bulkSelectToggle"
          />
          Select All
        </th>
        <th
          v-for="column in columns"
          :class="getClassesForSortableColumn(column.key)"
          @click="sortBy(column.key)"
          :key="column.key"
        >
          {{ column.label }}
        </th>
        <th v-if="hasActions">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr :class="{ hide: d.hide }" v-for="d in localData" :key="d.id">
        <td v-if="bulkActions.show">
          <input
            style="display: inline-block; width: inherit"
            type="checkbox"
            v-model="d._selected_"
            @change="checkAllBulkSelected(d)"
          />
          {{ bulkActions.label }}
        </td>
        <td v-for="column in columns" :key="column.key">
          {{ d[column.key] }}
        </td>
        <td>
          <slot v-if="hasActions" name="actions" :d="d"></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  data() {
    var localData = this.data.slice().map((d) => {
      d._selected_ = this.bulkActions.startSelected;
      return d;
    });
    return {
      localData: localData,
      search: "",
      sortKey: {
        key: null,
        direction: null,
      },
      allBulkSelected: this.bulkActions.startSelected,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    bulkActions: {
      type: Object,
      default: () => ({ show: false, startSelected: false, label: "" }),
    },
  },
  watch: {
    data(newData) {
      this.localData = newData.slice().map((d) => {
        d._selected_ = this.bulkActions.startSelected;
        return d;
      });
      this.sort();
      this.triggerSearch();
    },
  },
  methods: {
    bulkSelectToggle() {
      this.allBulkSelected = !this.allBulkSelected;
      for (const d of this.localData) {
        d._selected_ = this.allBulkSelected;
      }
    },
    checkAllBulkSelected() {
      this.allBulkSelected = this.localData.every((d) => {
        return d._selected_;
      });
    },
    sortBy(key) {
      var direction = 1;
      if (
        this.sortKey &&
        this.sortKey.key === key &&
        this.sortKey.direction === 1
      ) {
        direction = -1;
      }
      this.sortKey = { key, direction };
      this.sort();
    },
    sort() {
      const key = this.sortKey.key;
      return this.localData.sort((a, b) => {
        if (a[key] < b[key]) return -1 * this.sortKey.direction;
        if (a[key] > b[key]) return 1 * this.sortKey.direction;
        return 0;
      });
    },
    getClassesForSortableColumn(columnKey) {
      const isSortingColumn = this.sortKey.key === columnKey;
      return {
        sortable: true,
        sorting: isSortingColumn,
        down: isSortingColumn && this.sortKey.direction === 1,
        up: isSortingColumn && this.sortKey.direction === -1,
      };
    },
    triggerSearch() {
      this.search = this.search.toLowerCase();
      for (const d of this.localData) {
        d.hide = false;
        for (const column of this.columns) {
          if (d[column.key].toLowerCase().includes(this.search)) {
            d.hide = false;
            break;
          } else {
            d.hide = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
th.sortable {
  cursor: pointer;
}
th.sortable::after {
  content: "\25B4\25BE";
  margin-left: 5px;
}
th.sortable.down::after {
  content: "\25BE";
  margin-left: 5px;
}
th.sortable.up::after {
  content: "\25B4";
  margin-left: 5px;
}
tr.hide {
  display: none;
}
</style>
