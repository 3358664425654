<template>
  <div class="card">
    <h1>Create Unit</h1>
    <form @submit.prevent="triggerCreateUnit">
      <div class="input-button-wrapper">
        <input v-model="newUnitName" placeholder="Unit name" required />
        <button class="material-ui-button green" type="submit">
          <AddIcon />
          Create
        </button>
      </div>
    </form>
  </div>
  <div class="card">
    <h1>Units</h1>
    <table class="material-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="unit in units" :key="unit.id">
          <td><input v-model="unit.name" @change="updateUnit(unit)" /></td>
          <td>
            <button class="material-ui-button green" @click="updateUnit(unit)">
              <SaveIcon />
              Save
            </button>
            <button
              class="material-ui-button red"
              @click="deleteUnit({ id: unit.id })"
            >
              <DeleteIcon />
              Delete
            </button>
            <button
              class="material-ui-button blue"
              @click="$router.push(`/groups/${unit.id}`)"
            >
              <ViewIcon />
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "../styles/table-styles.css";
import { mapActions, mapState } from "vuex";
import AddIcon from "./icons/AddIcon.vue";
import SaveIcon from "./icons/SaveIcon.vue";
import DeleteIcon from "./icons/DeleteIcon.vue";
import ViewIcon from "./icons/ViewIcon.vue";

export default {
  components: {
    AddIcon,
    SaveIcon,
    DeleteIcon,
    ViewIcon,
  },
  data() {
    return {
      newUnitName: "",
    };
  },
  computed: {
    ...mapState(["units"]),
  },
  methods: {
    ...mapActions(["fetchUnits", "createUnit", "updateUnit", "deleteUnit"]),
    async triggerCreateUnit() {
      await this.createUnit({ name: this.newUnitName });
      this.newUnitName = "";
    },
  },
  created() {
    this.fetchUnits();
  },
};
</script>
