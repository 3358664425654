<template>
  <div class="form-container">
    <h2>Login</h2>
    <form @submit.prevent="triggerLogIn" class="form-form">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" v-model="password" required />
      </div>

      <button type="submit" class="form-button">Login</button>
      <router-link to="/signup" class="form-link">Sign Up</router-link>
      <router-link to="/forgotpassword" class="form-link"
        >Forgot Password</router-link
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "../styles/form-styles.css";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    async triggerLogIn() {
      try {
        await this.logIn({ email: this.email, password: this.password });
        this.$router.push("/");
      } catch (error) {
        console.error("Error logging in:", error.message);
      }
    },
  },
};
</script>
