<template>
  <div>
    <h1>
      Group Management:
      {{ this.otherUnit ? this.otherUnit.name : "Loading..." }}
    </h1>
    <div class="accordion">
      <div
        :class="{
          'accordion-below': true,
          open: openedGroup === groupName,
          card: true,
        }"
        v-for="(group, groupName) in groups"
        :key="groupName"
      >
        <button @click="toggle(groupName)" class="accordion-trigger">
          <h1>
            <button
              v-if="groupName !== 'none'"
              @click="
                openSendMessageModal(
                  $event,
                  groupName,
                  $refs[groupName + '-table'][0].localData
                )
              "
              class="material-ui-button green"
            >
              Send Message
            </button>
            {{ groupName.charAt(0).toUpperCase() + groupName.slice(1) }}
            <AccordionChevron :class="{ rotate: openedGroup === groupName }" />
          </h1>
        </button>
        <div class="accordion-content">
          <PortableTable
            :ref="groupName + '-table'"
            :data="groups[groupName]"
            :hasActions="false"
            :bulkActions="{
              show: true,
              startSelected: true,
              label: 'Send Message',
            }"
            :columns="[
              { key: '_displayName', label: 'Name' },
              { key: '_email', label: 'Email' },
              { key: '_phoneNumber', label: 'Phone Number' },
              { key: '_collection', label: 'User Type' },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
  <SendMessageModal
    v-if="showSendMessageModal"
    :sendingGroup="sendingGroup"
    :sendingUsers="sendingUsers"
    @close="closeSendMessageModal"
  />
</template>

<script>
import "../styles/table-styles.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { mapState, mapActions } from "vuex";
import PortableTable from "./PortableTable.vue";
import AccordionChevron from "./icons/AccordionChevron.vue";
import SendMessageModal from "./SendMessageModal.vue";

export default {
  components: {
    SendMessageModal,
    PortableTable,
    AccordionChevron,
  },
  props: ["unit_id"],
  data: () => {
    return {
      groups: {
        retaining: [],
        teaching: [],
        connect: [],
        prayer: [],
        none: [],
      },
      openedGroup: "retaining",
      showSendMessageModal: false,
      sendingGroup: {},
      sendingUsers: [],
    };
  },
  computed: {
    ...mapState(["otherUnit"]),
  },
  methods: {
    ...mapActions(["fetchOtherUnit"]),
    toggle(groupName) {
      this.openedGroup = this.openedGroup === groupName ? null : groupName;
    },
    openSendMessageModal(event, groupName, users) {
      event.stopPropagation();
      this.sendingUsers = users.filter((user) => user._selected_ && !user.hide);
      this.sendingGroup.groupName =
        groupName.charAt(0).toUpperCase() + groupName.slice(1);
      this.showSendMessageModal = true;
    },
    closeSendMessageModal() {
      this.showSendMessageModal = false;
    },
  },
  mounted: async function () {
    // TODO: this should be a computed property
    const collections = ["users", "manual_users"]; // TODO: these should be a constant/enum or i should just combine them into one collection
    const localGroups = {
      retaining: [],
      teaching: [],
      connect: [],
      prayer: [],
      none: [],
    };
    try {
      for (const collectionName of collections) {
        var q = collection(db, collectionName);
        q = query(q, where("usersRole.unit_member", "==", this.unit_id));
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.map((doc) => {
          const data = doc.data();
          data._displayName = data.displayName || "No Display Name";
          data._email = data.email || "No Email";
          data._phoneNumber = data.phoneNumber || "No Phone Number";
          data.collection = collectionName;
          data._collection =
            collectionName === "users" ? "Member" : "Manual Member";
          if (data.groups && data.groups.length > 0) {
            for (const index in data.groups) {
              // replace finding with retaining
              var groupName = data.groups[index] == "finding" ? "retaining" : data.groups[index];
              localGroups[groupName].push(data);
            }
          } else {
            localGroups.none.push(data);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    await this.fetchOtherUnit({ unit_id: this.unit_id });
    this.groups = localGroups;
  },
};
</script>

<style scoped>
.accordion {
  max-height: 100%;
  color: #444;
  padding: 8px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion-trigger > * {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-trigger {
  align-items: center;
  background-color: white;
  color: #444;
  cursor: pointer;
  /* padding: 6px; */
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.2s;
}

.accordion-content {
  max-height: 0px;
  padding: 0 18px;
  overflow: hidden;
  transition: max-height 0.1s ease;
}

.accordion-below {
  max-height: 423;
}

.accordion-below.open > .accordion-content {
  overflow-y: auto;
  max-height: 500px;
}

.accordion > div:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.rotate {
  transform: rotate(90deg);
}
</style>
