import { db } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

export const units = {
  state: {
    units: null,
    unit: null,
    otherUnit: null,
  },
  mutations: {
    setUnits(state, units) {
      var collator = new Intl.Collator([], { numeric: true });
      state.units = units.sort((a, b) => collator.compare(a.name, b.name));
    },
    setUnit(state, unit) {
      state.unit = unit;
    },
    setOtherUnit(state, unit) {
      state.otherUnit = unit;
    },
  },
  actions: {
    async fetchUnits({ commit }) {
      try {
        const unitsSnapshot = await getDocs(collection(db, "units"));
        const units = unitsSnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        commit("setUnits", units);
      } catch (error) {
        console.error("Error fetching units:", error.message);
      }
    },
    async fetchOtherUnit({ commit }, { unit_id }) {
      console.log(unit_id);
      try {
        const unitSnapshot = await getDoc(doc(db, "units", unit_id));
        const unit = { ...unitSnapshot.data(), id: unitSnapshot.id };
        commit("setOtherUnit", unit);
      } catch (error) {
        console.error("Error fetching unit:", error.message);
      }
    },
    async fetchUnit({ commit }) {
      try {
        const unitSnapshot = await getDoc(
          doc(db, "units", this.state.user.usersRole.unit_member)
        );
        const unit = { ...unitSnapshot.data(), id: unitSnapshot.id };
        commit("setUnit", unit);
      } catch (error) {
        console.error("Error fetching unit:", error.message);
      }
    },
    async createUnit({ dispatch }, { name }) {
      try {
        await addDoc(collection(db, "units"), {
          name,
        });
        dispatch("fetchUnits");
      } catch (error) {
        console.error("Error creating unit:", error.message);
      }
    },
    async deleteUnit({ dispatch }, { id }) {
      try {
        await deleteDoc(doc(db, "units", id));
        dispatch("fetchUnits");
      } catch (error) {
        console.error("Error deleting unit:", error.message);
      }
    },
    async updateUnit({ dispatch }, { id, name }) {
      try {
        await updateDoc(doc(db, "units", id), {
          name,
        });
        dispatch("fetchUnits");
      } catch (error) {
        console.error("Error updating unit:", error.message);
      }
    },
  },
};
