<template>
  <div v-if="user">
    <div class="card">
      <h1>
        Profile
        <button
          class="material-ui-button blue"
          @click="isEditProfileModalOpen = true"
        >
          <EditIcon />
          <span style="margin-left: 5px; margin-right: 5px">Edit</span>
        </button>
      </h1>

      <p><b>Display Name: </b> {{ user.displayName || user.email }}</p>
      <p>
        <b>Email: </b> {{ user.email }} -
        <span v-if="user.emailVerified">
          <CheckIcon style="margin-bottom: -6px; color: darkgreen" /> Verified
        </span>
        <span v-else>
          <ExclamationIcon style="margin-bottom: -6px; color: red" /> Not
          Verified
          <button
            class="material-ui-button green"
            @click="resendVerificationEmail"
          >
            Resend Verification Email
          </button>
          <span style="color: darkgreen" v-if="emailSent">
            Verification email sent!</span
          >
          <span style="color: red" v-if="emailSendError" class="error">
            {{ emailSendError }}</span
          >
        </span>
      </p>
      <p>
        <b>Phone: </b>
        <span v-if="user.phoneNumber"> {{ user.phoneNumber }} </span>
        <span v-else> <ExclamationIcon /> No Phone Number Entered </span>
      </p>
      <p>
        <b>Unit: </b>
        <span v-if="unit">
          {{ unit.name }}
        </span>
        <span v-else> <ExclamationIcon /> No Unit Assigned </span>
      </p>
    </div>
    <div class="group-cards">
      <div v-for="(group, groupName) in groups" :key="groupName" class="card">
        <h2>{{ group.displayName }}</h2>
        <p>{{ group.description }}</p>
        <div v-if="isUserInGroup(groupName)">
          <!-- TODO: I want these to be a switch -->
          <p>You are in this group.</p>
          <button
            class="material-ui-button red"
            @click="removeUserFromGroup({ groupName })"
          >
            Leave Group
          </button>
        </div>
        <div v-else>
          <button
            class="material-ui-button green"
            @click="addUserToGroup({ groupName })"
          >
            Join Group
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>You are not logged in.</p>
  </div>

  <EditProfileModal
    v-if="isEditProfileModalOpen"
    :email="user.email"
    :phoneNumber="user.phoneNumber"
    :name="user.displayName"
    :users-role="user.usersRole"
    @close-edit-profile-modal="this.isEditProfileModalOpen = false"
  />
</template>

<script>
import EditProfileModal from "@/components/EditProfileModal.vue"; // Adjust the path as needed
import CheckIcon from "./icons/CheckIcon.vue";
import ExclamationIcon from "./icons/ExclamationIcon.vue";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../firebase";
import { mapActions, mapState } from "vuex";
import EditIcon from "./icons/EditIcon.vue";

export default {
  components: {
    CheckIcon,
    ExclamationIcon,
    EditProfileModal,
    EditIcon,
  },
  computed: {
    ...mapState(["user", "unit"]),
  },
  data() {
    return {
      isEditProfileModalOpen: false,
      emailSent: false,
      emailSendError: null,
      groups: {
        prayer: {
          displayName: "Prayer Group",
          description: "This is a group for prayer.",
        },
        connect: {
          displayName: "Connect Group",
          description: "This is a group for connecting.",
        },
        teaching: {
          displayName: "Teaching Group",
          description: "This is a group for teaching.",
        },
        retaining: {
          displayName: "Retaining Group",
          description: "This is a group for retaining.",
        },
      },
    };
  },
  methods: {
    ...mapActions(["fetchUnit", "addUserToGroup", "removeUserFromGroup"]),
    async resendVerificationEmail() {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        try {
          await sendEmailVerification(user);
          this.emailSent = true;
          this.emailSendError = null;
        } catch (error) {
          this.emailSendError = error.message;
        }
      }
    },
    isUserInGroup(groupName) {
      return this.user.groups && this.user.groups.includes(groupName);
    },
  },
  async created() {
    await this.fetchUnit();
  },
};
</script>

<style scoped>
.group-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1rem;
}

@media (max-width: 768px) {
  .group-cards {
    grid-template-columns: 1fr;
  }
}
</style>
