import { createStore } from "vuex";
import { user } from "./user";
import { otherUsers } from "./other-users";
import { units } from "./units";

export default createStore({
  state: {
    ...user.state,
    ...units.state,
  },
  mutations: {
    ...user.mutations,
    ...units.mutations,
  },
  actions: {
    ...user.actions,
    ...otherUsers.actions,
    ...units.actions,
  },
  getters: {
    ...user.getters,
  },
});
