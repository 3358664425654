<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
};
</script>
