import { db } from "../firebase";
import { getDocs, collection, query, where } from "firebase/firestore";

export const cleanAndValidatePhoneNumber = (phoneNumber) => {
  // Remove non-numeric characters
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Assume US country code if no country code is provided
  const normalizedNumber = `+1${cleanedNumber}`;

  // Validate the phone number using a regex for E.164 format
  const isValidE164 = /^\+\d{11}$/.test(normalizedNumber);

  if (!isValidE164) {
    throw new Error("Invalid phone number format");
  }

  return normalizedNumber;
};

export const checkUniqueEmail = async (email) => {
  if (email == "") {
    return;
  }
  const q = query(collection(db, "users"), where("email", "==", email));
  const dbUserSnapShot = await getDocs(q);
  if (dbUserSnapShot.docs.length > 0) {
    throw new Error("User already uses this email");
  }
  const q2 = query(collection(db, "manual_users"), where("email", "==", email));
  const dbManualUseSnapShot = await getDocs(q2);
  if (dbManualUseSnapShot.docs.length > 0) {
    throw new Error("Manual User already uses this email");
  }
};

export const checkUniquePhoneNumber = async (phoneNumber) => {
  if (phoneNumber == "") {
    return;
  }
  const q = query(
    collection(db, "users"),
    where("phoneNumber", "==", phoneNumber)
  );
  const dbUserSnapShot = await getDocs(q);
  if (dbUserSnapShot.docs.length > 0) {
    throw new Error("User already uses this phone number");
  }
  const q2 = query(
    collection(db, "manual_users"),
    where("phoneNumber", "==", phoneNumber)
  );
  const dbManualUseSnapShot = await getDocs(q2);
  if (dbManualUseSnapShot.docs.length > 0) {
    throw new Error("Manual User already uses this phone number");
  }
};
