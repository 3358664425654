import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  doc,
  query,
  collection,
  where,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {} from "firebase/firestore";

import { cleanAndValidatePhoneNumber } from "./utils";

export const user = {
  state: { user: null },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
  actions: {
    // Login/Logout
    async logIn({ dispatch }, { email, password }) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const user = userCredential.user;

        dispatch("setupAuthUser");
        return user;
      } catch (error) {
        console.error("Error logging in:", error.message);
        throw error;
      }
    },
    async logOut({ commit }) {
      auth.signOut();
      commit("setUser", null);
    },
    // User
    async createUser({ commit }, { name, email, password, phone, unitId }) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        await updateProfile(auth.currentUser, {
          displayName: name,
        });

        if (phone.length > 0) {
          phone = cleanAndValidatePhoneNumber(phone);
        }
        const userData = {
          displayName: name,
          email: email,
          phoneNumber: phone,
          usersRole: {
            unit_member: unitId,
          },
          groups: [],
        };
        await setDoc(doc(db, "users", user.uid), userData);
        user.phoneNumber = userData.phoneNumber;
        user.usersRole = userData.usersRole;

        await sendEmailVerification(user);

        commit("setUser", user);
        return user;
      } catch (error) {
        console.error("Error signing up:", error.message);
      }
    },
    async sendPasswordResetEmail(_, { email }) {
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        console.error("Error sending password reset email:", error.message);
      }
    },
    async updateUser(
      { commit, dispatch },
      { name, email, phoneNumber, currentPassword, newPassword, usersRole }
    ) {
      const updateUser = {};
      try {
        await dispatch("logIn", {
          email: this.state.user.email,
          password: currentPassword,
        });
      } catch (error) {
        throw new Error("Unable to verify login");
      }

      try {
        const user = this.state.user;
        if (newPassword.length > 0 && newPassword != currentPassword) {
          await updatePassword(user, newPassword);
        }

        if (email != user.email) {
          await updateEmail(user, email);
          updateUser.email = email;
          await sendEmailVerification(user);
        }

        if (phoneNumber != user.phoneNumber) {
          if (phoneNumber.length > 0) {
            phoneNumber = cleanAndValidatePhoneNumber(phoneNumber);
          }
          updateUser.phoneNumber = phoneNumber;
        }

        if (name != user.displayName) {
          updateUser.displayName = name;
          await updateProfile(user, {
            displayName: name,
            // The email field is not needed here anymore
          });
        }

        if (usersRole) {
          updateUser.usersRole = usersRole;
        }

        if (Object.keys(updateUser).length > 0) {
          await updateDoc(doc(db, "users", user.uid), updateUser);
        }

        user.phoneNumber = phoneNumber;
        user.displayName = name;
        user.email = email;
        user.usersRole = usersRole;

        commit("setUser", user);
      } catch (error) {
        console.error("Error updating user:", error.message);
      }
    },
    async setupAuthUser({ commit }) {
      try {
        await new Promise((resolve, reject) => {
          auth.onAuthStateChanged(async (user) => {
            if (user) {
              const dbUser = await getDoc(doc(db, "users", user.uid));
              if (dbUser.data().phoneNumber) {
                user.phoneNumber = dbUser.data().phoneNumber;
              }
              user.usersRole = dbUser.data().usersRole;
              user.groups = dbUser.data().groups;

              const q = query(
                collection(db, "manual_users"),
                where("email", "==", user.email)
              );
              const querySnapshot = await getDocs(q);

              querySnapshot.forEach((d) => {
                deleteDoc(doc(db, "manual_users", d.id));
              });
            }
            commit("setUser", user);
            resolve();
          }, reject);
        });
      } catch (error) {
        console.error("Error checking auth state:", error.message);
      }
    },
    // *** Add/Remove User From Group ***
    async addUserToGroup({ commit }, { groupName }) {
      try {
        await updateDoc(doc(db, "users", this.state.user.uid), {
          groups: arrayUnion(groupName),
        });
        const user = this.state.user;
        if (!user.groups) user.groups = [];
        if (!user.groups.includes(groupName)) {
          user.groups.push(groupName);
        }
        commit("setUser", user);
      } catch (error) {
        console.error("Error adding user to group: ", error);
      }
    },
    async removeUserFromGroup({ commit }, { groupName }) {
      try {
        await updateDoc(doc(db, "users", this.state.user.uid), {
          groups: arrayRemove(groupName),
        });
        const user = this.state.user;
        if (!user.groups) user.groups = [];
        if (user.groups.includes(groupName)) {
          user.groups.splice(user.groups.indexOf(groupName), 1);
        }
        commit("setUser", user);
      } catch (error) {
        console.error("Error removing user from group: ", error);
      }
    },
  },
};
