<template>
  <generic-modal @close="closeModal">
    <template v-slot:header>
      <h2>Add Manual Member</h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="saveChanges">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="manualUser.displayName" />
        </div>
        <hr />
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="manualUser.email" />
        </div>
        <hr />
        <div class="form-group">
          <label for="phone">Phone Number:</label>
          <input type="tel" id="phone" v-model="manualUser.phoneNumber" />
        </div>
        <hr />
        <label for="groups">Groups:</label>
        <div
          style="padding-left: 20px"
          v-for="group in ['prayer', 'connect', 'teaching', 'retaining']"
          :key="group"
          class="form-group"
        >
          <label class="inline" :for="group">{{ group }}</label>
          <label class="switch">
            <input
              type="checkbox"
              :id="group"
              :value="group"
              v-model="manualUser.groups"
            />
            <span class="slider"></span>
          </label>
        </div>
        <hr />
        <div class="form-group">
          <label for="unit">Unit:</label>
          <select id="unit" v-model="manualUser.usersRole.unit_member">
            <option v-for="unit in units" :key="unit.id" :value="unit.id">
              {{ unit.name }}
            </option>
          </select>
        </div>
        <button class="form-button" type="submit">Create</button>
        <span style="color: red" class="error-message" v-if="errorMessage">
          {{ errorMessage }}
        </span>
      </form>
    </template>
  </generic-modal>
</template>

<script>
import { mapActions } from "vuex";
import GenericModal from "./GenericModal.vue";

export default {
  components: {
    GenericModal,
  },
  data() {
    return {
      manualUser: {
        displayName: "",
        email: "",
        phoneNumber: "",
        groups: [],
        usersRole: {
          unit_member: this.units[Object.keys(this.units)[0]].id,
        },
      },
      errorMessage: null,
    };
  },
  props: {
    units: { type: Object, required: true },
    user: { type: Object },
  },
  methods: {
    ...mapActions(["addOtherUser"]),
    closeModal() {
      this.$emit("close");
    },
    async saveChanges() {
      try {
        await this.addOtherUser({
          collectionName: "manual_users",
          user: this.manualUser,
        });
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.errorMessage = error;
      }
    },
  },
};
</script>
