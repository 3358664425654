import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import UnitsManagement from "../components/UnitsManagement.vue";
import GroupManagement from "../components/GroupManagement.vue";
import MemberManagement from "../components/MemberManagement.vue";
import LogIn from "../components/LogIn.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import SignUp from "../components/SignUp.vue";
import store from "../store";

const routes = [
  { path: "/", component: HomePage, meta: { requiresAuth: true } },
  { path: "/units", component: UnitsManagement, meta: { requiresAuth: true } },
  {
    path: "/members",
    component: MemberManagement,
    meta: { requiresAuth: true },
  },
  {
    path: "/groups/:unit_id",
    component: GroupManagement,
    props: true,
    meta: { requiresAuth: true },
  },
  { path: "/login", component: LogIn },
  { path: "/forgotpassword", component: ForgotPassword },
  { path: "/signup", component: SignUp },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  await store.dispatch("setupAuthUser");

  if (requiresAuth && !store.getters.isAuthenticated) {
    next("/login");
  } else if (!requiresAuth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
