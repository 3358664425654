<template>
  <generic-modal @close="closeModal">
    <template v-slot:header>
      <h2>Edit Profile</h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="saveChanges">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" v-model="editedProfile.name" />
        </div>
        <hr />
        <div class="form-group">
          <label for="email">Email:</label>
          <input id="email" type="email" v-model="editedProfile.email" />
        </div>
        <hr />
        <div class="form-group">
          <label for="phoneNumber">Phone:</label>
          <input
            id="phoneNumber"
            type="tel"
            v-model="editedProfile.phoneNumber"
          />
        </div>
        <hr />
        <div class="form-group">
          <label for="unit">Unit:</label>
          <select id="unit" v-model="editedProfile.usersRole.unit_member">
            <option v-for="unit in units" :key="unit.id" :value="unit.id">
              {{ unit.name }}
            </option>
          </select>
        </div>
        <hr />
        <div class="form-group">
          <label class="inline" for="changePassword">Change Password:</label>
          <label class="switch">
            <input
              type="checkbox"
              v-model="changePassword"
              id="changePassword"
            />
            <span class="slider"></span>
          </label>
        </div>
        <div v-if="changePassword" style="padding-left: 20px">
          <div class="form-group">
            <label for="newPassword">New Password:</label>
            <input
              type="password"
              required
              v-model="editedProfile.newPassword"
              id="newPassword"
            />
          </div>
          <div class="form-group">
            <label for="repeatNewPassword">Repeat New Password:</label>
            <input
              type="password"
              required
              v-model="editedProfile.repeatNewPassword"
              id="repeatNewPassword"
            />
          </div>
        </div>
        <hr />
        <div class="form-group">
          <label for="phoneNumber">Current Password:</label>
          <input
            type="password"
            required
            v-model="editedProfile.currentPassword"
          />
        </div>
        <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
        <button type="submit" class="form-button">Save Changes</button>
      </form>
    </template>
  </generic-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GenericModal from "./GenericModal.vue";

export default {
  components: {
    GenericModal,
  },
  data() {
    return {
      editedProfile: {
        email: this.email,
        phoneNumber: this.phoneNumber ? this.phoneNumber.replace("+1", "") : "",
        name: this.name,
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
        usersRole: this.usersRole,
      },
      changePassword: false,
      errorMessage: null,
    };
  },
  props: {
    // Add props here
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    usersRole: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["units"]),
  },
  methods: {
    ...mapActions(["fetchUnits", "updateUser"]),
    closeModal() {
      this.$emit("close-edit-profile-modal");
    },
    async saveChanges() {
      try {
        if (!this.changePassword) {
          this.editedProfile.newPassword = "";
          this.editedProfile.repeatNewPassword = "";
        }
        if (
          this.editedProfile.newPassword !==
          this.editedProfile.repeatNewPassword
        ) {
          throw new Error("New passwords do not match");
        }
        if (
          this.editedProfile.newPassword.length > 0 &&
          this.editedProfile.newPassword.length < 6
        ) {
          throw new Error("New password must be at least 6 characters");
        }
        await this.updateUser(this.editedProfile);
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
        return;
      }
      this.$emit("close-edit-profile-modal");
    },
  },
  created() {
    this.fetchUnits();
  },
};
</script>
