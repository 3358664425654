<template>
  <div class="form-container">
    <h2>Sign Up</h2>
    <form @submit.prevent="triggerSignUp" class="form-form">
      <label for="unit">Unit:</label>
      <select v-model="unitId" required>
        <option disabled value="">Please select a unit</option>
        <option v-for="unit in units" :key="unit.id" :value="unit.id">
          {{ unit.name }}
        </option>
      </select>
      <div class="form-group">
        <label for="email">Name:</label>
        <input type="text" v-model="name" required />
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" v-model="password" required />
      </div>
      <div class="form-group">
        <label for="phone">Phone (Optional):</label>
        <input type="tel" v-model="phone" />
      </div>

      <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
      <button type="submit" class="form-button">Sign Up</button>
      <router-link to="/login" class="form-link">Login</router-link>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "../styles/form-styles.css";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      phone: "",
      unitId: "",
      errorMessage: null,
    };
  },
  computed: {
    ...mapState(["units"]),
  },
  methods: {
    ...mapActions(["createUser", "fetchUnits"]),
    async triggerSignUp() {
      try {
        await this.createUser({
          name: this.name,
          email: this.email,
          password: this.password,
          phone: this.phone,
          unitId: this.unitId,
        });
        this.$router.push("/");
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
  created() {
    this.fetchUnits();
  },
};
</script>
