<template>
  <div class="card" v-for="(users, collection) in allUsers" :key="collection">
    <!-- TODO: i want to make these accordions with the top panel open. Only the table should scroll with the headers being sticky -->
    <h1>
      {{ collectionToDisplay(collection) }}
      <button
        class="material-ui-button green"
        :onclick="openAddManualUserModel"
        v-if="collection == 'manual_users'"
      >
        <AddIcon />
        Create
      </button>
    </h1>
    <PortableTable
      :data="users"
      :hasActions="true"
      :columns="[
        { key: '_displayName', label: 'Name' },
        { key: '_email', label: 'Email' },
        { key: '_phoneNumber', label: 'Phone Number' },
        { key: '_groups', label: 'Groups' },
        { key: '_unitName', label: 'Unit' },
        { key: '_role', label: 'Role' },
      ]"
    >
      <template v-slot:actions="{ d: user }">
        <button
          class="material-ui-button green"
          @click="openEditMemberModal(collection, user)"
        >
          <EditIcon />
          Edit
        </button>
        <button
          class="material-ui-button red"
          @click="triggerDeleteOtherUser(collection, user)"
        >
          <DeleteIcon />
          Delete
        </button>
      </template>
    </PortableTable>
  </div>
  <EditUserModal
    v-if="showEditUserModal"
    :user="userToEdit"
    :currentUser="user"
    :collectionName="editingCollectionName"
    :units="unitsById"
    @close="closeEditMemberModal"
  />
  <AddManualUserModal
    v-if="showAddManualUserModal"
    :units="unitsById"
    @close="closeAddManualUserModal"
  />
</template>

<script>
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { mapState, mapActions } from "vuex";
import EditUserModal from "./EditUserModal.vue";
import AddIcon from "./icons/AddIcon.vue";
import EditIcon from "./icons/EditIcon.vue";
import DeleteIcon from "./icons/DeleteIcon.vue";
import AddManualUserModal from "./AddManualUserModal.vue";
import PortableTable from "./PortableTable.vue";

export default {
  components: {
    AddIcon,
    EditIcon,
    DeleteIcon,
    EditUserModal,
    AddManualUserModal,
    PortableTable,
  },
  data() {
    return {
      allUsers: {},
      userToEdit: null,
      editingCollectionName: "",
      showEditUserModal: false,
      showAddManualUserModal: false,
    };
  },
  computed: {
    ...mapState(["units", "user"]),
    unitsById() {
      const unitsById = {};
      for (const unit of this.units) {
        if (
          this.user.usersRole.super_admin ||
          this.user.usersRole.unit_admin === unit.id
        ) {
          unitsById[unit.id] = unit;
        }
      }
      return unitsById;
    },
  },
  created() {
    this.fetchUnits();
  },
  methods: {
    ...mapActions(["fetchUnits", "deleteOtherUser"]),
    openEditMemberModal(collection, user) {
      this.userToEdit = user;
      this.editingCollectionName = collection;
      this.showEditUserModal = true;
    },
    async closeEditMemberModal() {
      // TODO: don't need to do this on cancel
      await this.fetchUserData();
      this.showEditUserModal = false;
    },
    openAddManualUserModel(user) {
      this.userToEdit = user;
      this.showAddManualUserModal = true;
    },
    async closeAddManualUserModal() {
      // TODO: don't need to do this on cancel
      await this.fetchUserData();
      this.showAddManualUserModal = false;
    },
    getUserRole(user) {
      if (user.usersRole.super_admin) {
        return "Super Admin"; // TODO: this should be a constant/enum and be on the user object in the store
      } else if (user.usersRole.unit_admin) {
        return "Unit Manager";
      } else {
        return "Unit Member";
      }
    },
    getUnitName(unitId) {
      return this.unitsById[unitId] ? this.unitsById[unitId].name : "N/A";
    },
    collectionToDisplay(str) {
      switch (str) {
        case "users":
          return "Members";
        case "manual_users":
          return "Manual Members";
        default:
          return str;
      }
    },
    fetchUserData: async function () {
      // TODO: need to move this to the store
      const collections = ["users", "manual_users"]; // TODO: these should be a constant/enum or i should just combine them into one collection
      try {
        for (const collectionName of collections) {
          var q = collection(db, collectionName);
          if (this.user.usersRole.unit_admin) {
            q = query(
              q,
              where(
                "usersRole.unit_member", // TODO: this should be a constant/enum (all field names should)
                "==",
                this.user.usersRole.unit_member
              )
            );
          }
          const snapshot = await getDocs(q);
          this.allUsers[collectionName] = snapshot.docs.map((doc) => {
            const user = { id: doc.id, ...doc.data() };
            user._role = "";
            if (collectionName === "users") {
              user._role = this.getUserRole(user);
            }
            // replace the finding group with retaining
            user.groups = user.groups.map((group) =>
              group === "finding" ? "retaining" : group
            );
            user._groups =
              user.groups && user.groups.length > 0
                ? user.groups.join(", ")
                : "No Groups";
            user._unitName = this.getUnitName(user.usersRole.unit_member);
            user._displayName = user.displayName || "No Display Name";
            user._email = user.email || "No Email";
            user._phoneNumber = user.phoneNumber || "No Phone Number";
            return user;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    triggerDeleteOtherUser: async function (collectionName, user) {
      await this.deleteOtherUser({ collectionName, user });
      await this.fetchUserData();
    },
  },
  mounted: async function () {
    await this.fetchUserData();
  },
};
</script>
