<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "./firebase";
import Navbar from "./components/NavBar.vue";

export default {
  components: {
    Navbar,
  },
  name: "App",
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      auth.signOut();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
#app,
html,
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}
</style>
