import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBCGAdKzWrBHQwBaInA82yHeTnsPhEgHEQ",
  authDomain: "washington-utah-stake-mission.firebaseapp.com",
  projectId: "washington-utah-stake-mission",
  storageBucket: "washington-utah-stake-mission.appspot.com",
  messagingSenderId: "1030906884562",
  appId: "1:1030906884562:web:fc215b19751ca2b440180b",
  measurementId: "G-FGWQJRQG65",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
setPersistence(auth, browserSessionPersistence);

export { app, auth, db };
